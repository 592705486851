@import "../assets/css/variables.scss";

.footer {
  background-color: $primary-darker-color;
  color: white;
  padding: 20px;

  .footer-copy {
    font-size: 14px;

    .footer-copy-column2 {
      text-align: center; 

      @media (min-width: 768px) {
        text-align: right;
      }
    }
  }

  .app-links {
    margin: 20px 0;

    span {
      display: block;
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 8px;
    }

    .app-download-buttons {
      .app-download-buttons-outter {
        justify-content: center;

        @media (min-width: 768px) {
          justify-content: flex-end;
        }
      }
      .app-download-buttons-inner {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;
      }
    }
    

    // display: flex;
    // flex-direction: column;
    // margin-bottom: 24px;

    // a {
    //   margin-bottom: 8px;
    //   display: flex;
    //   align-items: center;

    //   justify-content: center;

    //   @media (min-width: 768px) {
    //     justify-content: flex-end;
    //   }

      

    //   gap: 4px;

    //   i {
    //     font-size: 18px;
    //   }
    // }
  }

  .social-links {
    display: flex;
    gap: 8px;

    a {
      font-size: 24px;
    }
  }
  
  .logo {
    width: 100px;
    margin: 0 auto;
    display: block;
  }

  .app-download-buttons {
    align-self: center;

    .app-download-buttons-inner {
      flex-direction: column;

      a {
        img {
          width: 150px;
        }
      }
    }
  }

  .footer-column3 {
    display: flex;
    justify-content: center;
  }
  

  .sitemap {
    a {
      color: white;
      text-decoration: none;

      &:hover {
        color: $primary-color;
      }    
    }
  }

  a {
    color: $primary-color;
    text-decoration: none;

    &:hover {
      color: white;
    }
  }
}
