@import "https://cdn.jsdelivr.net/npm/bulma@1.0.1/css/bulma.min.css";
@import "https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@6.5.2/css/all.min.css";

@import "./variables.scss";

html {
  scroll-behavior: smooth;
  background-color: $primary-darker-color;
}

body {
  background-color: #fff;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}

a {
  // color: $primary-color;
  text-decoration: none;
  &:hover {
    color: $primary-darker-color;
  }
}

header {
  background-color: #fff;
  color: #313131;
  border-bottom-width: 1px;
  border-color: #313131;
  text-align: center;

  ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    gap: 10px;

    align-self: flex-end;

    a {
      text-decoration: none;
      color: #313131;

      &:hover {
        color: red;
      }
    }
  }
}

