.download-app {
  p {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
  }

  .app-download-buttons {
    margin-top: 60px;

    justify-content: center;

    .app-download-buttons-outter {
      flex-direction: column;
      justify-content: center;
    }

    .app-download-buttons-inner {
      align-items: center;    
      gap: 4px;
  
      img {
        height: 50px;
      }
    }
  }
  
}