.app-download-buttons {
  .app-download-buttons-outter {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  
  .app-download-buttons-inner {
    display: flex;
    flex-direction: row;
    gap: 8;
  }
}
