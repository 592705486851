@import "../assets/css/variables.scss";

#home-how-it-works {
  padding-bottom: 50px;

}

.hero {
  // .title {
  //   text-align: center;
  // }

  .hero-screenshots {
    display: flex;
    justify-content: center;
    img {
      width: 200px;      
    }
  }

  .title {
    text-align: center;
  }

  &.hero-top {
    background-color: #5deac7;

    .hero-info {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      img.logo {
        width: 120px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
      }

      .title {
        margin-top: 16px;
        margin-bottom: 0;
        line-height: 0.7em;
        color: $primary-dark-color;
      }

      .subtitle {
        color: $primary-dark-color;
      }
      
      p {
        margin: 8px 0;
        text-align: center;
      }
    }

    .app-download-buttons {
      margin-top: 16px;

      .app-download-buttons-inner {
        gap: 8px;
        a {
          img {
            height: 50px;
          }
        }
      }
    }
  }
}

.feature {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  margin-top: 20px;

  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: $primary-color;

    span {
      font-size: 24px;
      font-weight: 600;
      color: $primary-darker-color;
    }
  }

  i {
    font-size: 2rem;
    color: $primary-dark-color;

    margin-right: 10px;
  }

  .feature-title {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .feature-description {
    font-size: 1rem;
  }
}